@import '@styles/variables';

.input-group-dropdown {
  .form-control-placeholder {
    width: calc(100% - 90px);
    padding: $input-padding-y $input-padding-x;
    color: $darkgreen;
    appearance: none; // Fix appearance for date inputs in Safari
    background-color: $input-bg;
    font-family: $input-font-family;
    // font-size: $input-font-size;
    font-size: $realtext-input-placeholder-text-size; // placeholder sizes 15px, input sizes 16px
    font-weight: $input-font-weight;
    // line-height: $input-line-height;
  }
  &.disabled {
    background: transparent !important;
    border: none !important;
  }
}
.input-group-dropdown-list {
  height: 238px;
  overflow: hidden;
  overflow-y: scroll;

  .input-group-dropdown-item {
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    line-height: normal;
  }
}

// .input-group-dropdown-list {
//   display: flex;
//   flex-direction: column;
//   height: 238px;
//   padding: 16px 16px 16px 12px;
//   align-items: flex-start;
//   gap: 24px;
//   align-self: stretch;
//   border-radius: var(--Spacing-Stytem-radius-md, 20px);
//   background: #fff;
//   .input-group-dropdown-item {
//     display: flex;
//     width: 323px;
//     height: 32px;
//     padding: 3px var(--Spacing-Stytem-spacing-sm, 16px);
//     align-items: center;
//     gap: 10px;
//     flex-shrink: 0;
//     &.selected {
//       display: flex;
//       width: 323px;
//       height: 32px;
//       padding: 3px var(--Spacing-Stytem-spacing-sm, 16px);
//       align-items: center;
//       gap: 10px;
//       flex-shrink: 0;
//       border-radius: 1224px;
//       background: var(--Color-mint-Mint-100, #33b6ae);

//       color: var(--Color-base-white, #fff);

//       /* Fill-In-Fields/Input_normal */
//       font-family: "Real Text";
//       font-size: 15px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//     }
//   }
// }
