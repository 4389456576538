@import '@styles/variables';
@import '@styles/mixins';

.btn-primary {
  .btn-label {
    padding-top: 2px;
  }
  .btn-icon {
    margin-right: -6px;
  }

  &.btn-default {
    @include primary-default-btn;
  }

  &.btn-light {
    @include primary-light-btn;
  }

  &.btn-mint {
    @include primary-mint-btn;
  }
}
