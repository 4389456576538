@import '@styles/variables';

.input-group-lg {
  border-radius: $input-border-radius;

  .input-group {
    background-color: gold;
  }

  .input-group-text .app-icon {
    display: flex;
  }
  .form-control {
    min-height: 56px;
    color: $darkgreen-50;
    font-family: $input-font-family-voucher;
    font-size: $input-font-size-voucher !important;
    font-weight: $input-font-weight-voucher;
    line-height: normal;
    &:focus {
      color: $darkgreen-50;
    }
    &.form-code{
      &::placeholder {
        font-size: $input-font-size-voucher;
        font-weight: $input-font-weight-voucher;
      }
    }
  }
}

