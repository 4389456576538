@import '@styles/variables';
@import '@styles/mixins';

.infobox-arrow {
  &.infobox-light {
    @include infobox-light;
  }
  &.infobox-dark {
    @include infobox-dark;
  }
  &.infobox-warning {
    @include infobox-warning;
  }
  &.infobox-success {
    @include infobox-success;
  }
}
