@import '@styles/variables';
@import '@styles/mixins';
@import '@theme/variables.scss';

.btn-counter {
  @include common-counter;

  .btn-label {
    color: $darkgreen;
    padding-top: 2px;
    font-size: $font-26;
  }

  &.btn-light {
    background: $white;
    border: 1px solid transparent;
  }

  &.btn-dark {
    background: rgba($white, 0.2);
    border: 1px solid rgba($white, 0.8);
  }
}
