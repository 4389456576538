// default
.container-page1 {
  padding-top: 40px;
  padding-bottom: 80px;
}

// page with bottom image
.container-page2 {
  padding-top: 68px;
  padding-bottom: 0;
}

// pages with activation bar
.container-page3 {
  padding-top: 58px;
  padding-bottom: 80px;
}