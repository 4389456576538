@import '@theme/variables.scss';
@import '@styles/variables';

main a {
  // color: $primary;
  // font-family: $font-family-poppins-medium;
  // font-size: 0.813rem;
  // letter-spacing: $character-spacing-0-3;
  // line-height: $line-height-xs;

  // font-family: $font-family-doomsday;
  // font-size: $font-size-md;
  font-weight: $font-weight-bold;
  // letter-spacing: $character-spacing-0-28;
  // color: $darkgreen;

  // &:focus-visible {
  //   outline: none;
  // }

  // &:hover {
  //   color: $primary;
  // }
}

.link-light-blue {
  color: $blue-300;

  &:hover {
    color: $white;
  }
}
// .fussnote-link {
  // font-family: $font-family-poppins-medium;
  // font-size: $font-size-xs;
  // color: $primary;
  // letter-spacing: $character-spacing-0-26;
  // line-height: $line-height-19;
  // text-decoration: $text-underline;
// }
