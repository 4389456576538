@import '@styles/variables';
@import '@styles/mixins';

.btn-secondary {
  .btn-label {
    padding-top: 2px;
  }
  &.btn-default {
    @include secondary-default-btn;
  }

  &.btn-light {
    @include secondary-light-btn;
  }

  &.btn-dark {
    @include secondary-dark-btn;
  }

  &.btn-mint {
    @include secondary-mint-btn;
  }
}
