@import '@theme/variables.scss';
@import '@styles/variables';


.main-content {
  .upper-div {
    .title {
      p {
        color: $white;
      }
    }
  }
  .down-div {
    .stepper-progress {
      top: 18px;
    }
  }
}
