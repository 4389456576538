@import 'variables';

// Button Mixins
@mixin common-primary-btn {
  display: inline-flex;
  min-height: 48px;
  padding: 14px 30px;
  border-radius: 30px;
  outline: 0;
  border: none;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // Fonts config
  text-align: center;
  /* Doomsday/Button */
  font-family: $font-family-doomsday;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 15px */
  letter-spacing: 1.8px;
  text-transform: uppercase;

  &:not(:disabled):hover {
    scale: 1.1;
  }
}

@mixin common-counter {
  // display: inline-flex;
  min-height: 48px;
  padding: 0px 12px;
  border-radius: 30px;
  outline: 0;
  border: none;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  cursor: pointer;
  // Fonts config
  text-align: center;
  /* Doomsday/Button */
  font-family: $font-family-doomsday;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 15px */
}

// Primary Button
@mixin primary-default-btn {
  @include common-primary-btn;

  background-color: $darkgreen;
  color: $white;

  &:hover {
    background-color: $darkgreen-75;
  }

  &:focus {
    background-color: $darkgreen;
  }

  &:disabled {
    background-color: $darkgreen-50;
    cursor: not-allowed;
  }
}

// Primary Light Button
@mixin primary-light-btn {
  @include common-primary-btn;

  background-color: $white;
  color: $darkgreen;

  &:hover {
    background-color: $darkgreen-75;
    color: $white;
    img {
      fill: $white;
    }
  }

  &:focus {
    background-color: $darkgreen-50;
  }

  &:disabled {
    background-color: $darkgreen-50;
    cursor: not-allowed;
  }
}

// Primary Mint Button
@mixin primary-mint-btn {
  @include common-primary-btn;

  background-color: $mint-100;
  color: $white;

  &:hover {
    background-color: $darkgreen-75;
  }

  &:focus {
    background-color: $mint-100;
  }

  &:disabled {
    background-color: $darkgreen-50;
    cursor: not-allowed;
  }
}

// Button Mixins
@mixin common-secondary-btn {
  display: inline-flex;
  padding: 11px 0px;
  border-radius: 30px;
  outline: 0;
  border: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // Fonts config
  text-align: center;
  /* Doomsday/Button */
  font-family: $font-family-doomsday-medium;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 120% */
}

// Primary Button
@mixin secondary-default-btn {
  @include common-secondary-btn;

  background-color: transparent;
  color: $darkgreen;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

// secondary Light Button
@mixin secondary-light-btn {
  @include common-secondary-btn;

  background-color: transparent;
  color: $white;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

// secondary dark Button
@mixin secondary-dark-btn {
  @include common-secondary-btn;

  background-color: transparent;
  color: $gray-100;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

// secondary Mint Button
@mixin secondary-mint-btn {
  @include common-secondary-btn;

  background-color: transparent;
  color: $mint-100;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

// Switch Button
@mixin primary-toggle-btn {
  padding: 0;
  position: relative;
  border: none;
  height: 27px;
  width: 47px;
  border-radius: 27px;
  background-color: #83a6a8;

  &:focus,
  &.focus {
    &,
    &.active {
      outline: none;
    }
  }

  > .handle {
    position: absolute;
    top: 2.9px; // (27px * 0.25) / 2;
    left: calc((27px * 0.25) / 2);
    width: 27px * 0.75;
    height: 27px * 0.75;
    border-radius: 27px * 0.75;
    background: #fff;
    transition: left 0.25s;
    box-shadow: 5px 1px 6px -2px #0000005c;
  }

  &.active {
    transition: background-color 0.25s;
    background-color: $darkgreen;

    > .handle {
      left: 24px;
      transition: left 0.25s;
    }

    &:before {
      opacity: 0.5;
    }

    &:after {
      opacity: 1;
    }
  }
}

// Radio Button
@mixin primary-radio-btn {
  padding: 0;
  position: relative;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: $white;
  border: 2px solid $gray-20;

  &:focus,
  &.focus {
    &,
    &.active {
      outline: none;
    }
  }

  > .handle {
    display: none;
  }

  &.active {
    > .handle {
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 15px;
      background: $darkgreen;
      transition: left 0.25s;
    }

    &:before {
      opacity: 0.5;
    }

    &:after {
      opacity: 1;
    }
  }
}

// Info Box common css
@mixin common-infobox {
  font-family: $font-family-realtext;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 16px;
  // max-width: 328px;
}

// Info Box warning
@mixin infobox-warning {
  @include common-infobox;
  background-color: $warning;
  color: $gray-100;
}

// Info Box success
@mixin infobox-success {
  @include common-infobox;
  background-color: $mint-100;
  color: $white;
}

// Info Box light
@mixin infobox-light {
  @include common-infobox;
  background-color: $white;
  color: $gray-100;
}

// Info Box dark
@mixin infobox-dark {
  @include common-infobox;
  background-color: rgba(255, 255, 255, 0.4);
  color: $gray-100;
}

// Info Box Toggle
@mixin infobox-toggle {
  @include common-infobox;
  background-color: rgba(255, 255, 255, 0.6);
  color: $gray-100;
}
