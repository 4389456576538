.bubble-tab {
  display: flex;
  flex-shrink: 0;
  
  .bubble {
    position: relative;
    min-width: 97px;
    width: auto;
    padding: 15px;
    border-radius: 50px;
    text-align: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    
    .bubble-icon {
      .app-icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .bubble-tip {
    position: absolute;
    top: 100%;
    border-width: 10px 15px 0 0;
    border-style: solid;
    border-color: currentColor transparent;
    bottom: -20px;
    left: 50%;
  }

  // tip position
  .bubble-tip-right {
    border-width: 10px 15px 0 0;
  }

  .bubble-tip-left {
    border-width: 10px 0px 0px 15px;
  }
}
