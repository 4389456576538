.footer-custom {
  .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .footer-custom {
    padding: 16px 0;
    .nav-link {
      padding: 6px 24px;
    }
  }
}
