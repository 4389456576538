@import '@theme/variables.scss';

.dashboard-credit {
  background: rgba($white, 0.4);
  padding: 10px 14px 5px 14px;
  border-radius: 1rem;
  .up-div {
    .text {
      .credit-price {
        margin-left: 10px;
      }
    }
  }
  .plus-icon {
    margin-left: 10px;
  }
}
