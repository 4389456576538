@import '@styles/variables';
@import '@theme/variables';

.form-label {
  font-family: $form-label-font-family;
  line-height: $form-label-line-height;
  letter-spacing: $form-label-letter-spacing;
  text-transform: $text-uppercase;
}

.form-control {
  &.form-textarea {
    border-radius: 20px !important;
    // border: 1px solid rgba($white, 0.6);
    &:focus {
      border: 2px solid rgba($white, 1);
    }
    &.invalid {
      border: 2px solid rgba($danger, 0.6);
    }
  }
  &.invalid {
    border: 2px solid rgba($danger, 0.6);
  }
  // &:disabled {
  //   background: transparent !important;
  //   border: none !important;
  // }
}
input#placeholder,
input::placeholder,
input::-webkit-input-placeholder,
input:-moz-placeholder,
.form-control::placeholder,
.form-control::-webkit-input-placeholder,
.form-control:-ms-input-placeholder {
  color: $input-placeholder-color !important;
  font-size: $h4-font-size;
}

::-webkit-input-placeholder {
  color: $input-placeholder-color !important;
  font-size: $h4-font-size;
}

::-moz-placeholder {
  color: $input-placeholder-color !important;
  font-size: $h4-font-size;
}

::-ms-placeholder {
  color: $input-placeholder-color !important;
  font-size: $h4-font-size;
}

::placeholder {
  color: $input-placeholder-color !important;
  font-size: $h4-font-size;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(185 232 229) inset !important;
  color: $input-color;
  -webkit-text-fill-color: $input-color !important;
  font-family: $form-label-font-family;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: rgba($white, 0.2) 9999s ease-in-out 0s;
}

.form-control {
  &.form-textarea {
    // border: 2px solid rgba($white, 0.6);
    border: 1px solid rgba($white, 0.6);
    border-radius: $input-border-radius;

    &.gray-border {
      border: 1px solid $gray-40;
      &.focus,
      &.touched {
        border: 1px solid $gray-40;
      }
      &.valid {
        border: 1px solid rgba($success, 0.6);
      }

      &.invalid {
        border: 1px solid rgba($danger, 0.6);
      }
    }

    &.focus,
    &.touched {
      border: 2px solid rgba($white, 1);
    }
    &.valid {
      border: 2px solid rgba($success, 0.6);
    }

    &.invalid {
      border: 2px solid rgba($danger, 0.6) !important;
    }
    // &.disabled {
    //   background: rgba($white, 0.2);
    //   border: none !important;
    // }

    // .input-group-text.end-icon {
    //   border: none !important;
    //   border-top-right-radius: $input-border-radius !important;
    //   border-bottom-right-radius: $input-border-radius !important;
    // }
  }
}
