// Imports: https://getbootstrap.com/docs/5.2/customize/sass/#importing

// TODO: comment unused bootstrap parts before going to production

// Option A: Include all of Bootstrap
// Include any default variable overrides here (though functions won't be available)
// @import '~bootstrap/scss/bootstrap.scss';
// Then add additional custom code here

// Option B: Include parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '~bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'scrollbar';

// 3. Include remainder of required Bootstrap stylesheets
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark.scss';

// 4. Include any default map overrides here
@import 'maps';

// 5. Include remainder of required parts
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/helpers';
// @import "~bootstrap/scss/code";
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
// @import "~bootstrap/scss/buttons";
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group"; // Requires forms
// @import "~bootstrap/scss/custom-forms";
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar'; // Requires nav
@import '~bootstrap/scss/offcanvas';
@import '~bootstrap/scss/accordion';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
// @import "~bootstrap/scss/jumbotron"; // Deprecated
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
// @import "~bootstrap/scss/media";
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal'; // Requires transitions
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
// @import "bootstrap/scss/print";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '~bootstrap/scss/utilities/api';

// 8. Add additional custom code here
@import 'text';
@import 'utilities';
@import 'showcase';
@import 'popup';
@import 'containers';

// Animation css
@import '~animate.css/animate.css';


@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
