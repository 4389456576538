@import '@styles/variables';
@import '@theme/variables';

.tooltip {
  opacity: 1 !important;
  .tooltip-inner {
    width: 169px !important;
    text-align: $text-l;
    font-size: $font-13 !important;
    font-style: normal !important;
    font-weight: 375 !important;
    line-height: 16px !important;
  }
  .tooltip-arrow::before {
    scale: 3;
  }
}
