@import '~bootstrap/scss/functions', '~bootstrap/scss/variables';
// scss-docs-start color-variables
$white: #fff;
$black: #000;
$blue: #153d63;
$blue-300: #88c2eb;
$blue-400: #b1d8f1;
$blue-500: #7ebbe6;
$red: #dc3545;
$red-100: #da435f;
$yellow: #ffc107;
$light-yellow: #ffff12;
$green: #198754;
$black-100: #1c1c1c;
$warning: #ffc400;
$danger: #e24c4b;
$success: #75dd7d;
$grey: #6c757d;
$grey-100: #aaaaaa;
$grey-200: #a3a2a2;
$grey-300: #f39100;
$grey-400: #a3d911;
$grey-500: #4eaba1;
$grey-600: #bc99c7;
$cyan: #20b5ea;
$yellow-300: #d3b86f;
$light-blue: #53e0db;
$primary-light-100: rgb(126, 187, 230, 0.5);
$error-light-100: rgba(220, 53, 69, 0.5);
$input-bg: #d0d0d0;

// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  'black': $black,
  'white': $white,
  'blue': $blue,
  'blue-light': $blue-300,
  'red': $red,
  'red-light': $red-100,
  'yellow': $yellow,
  'green': $green,
  'black-light': $black-100,
  'warning': $warning,
  'danger': $danger,
  'success': $success,
  'grey': $grey,
  'grey-100': $grey-100,
  'grey-200': $grey-200,
  'grey-300': $grey-300,
  'grey-400': $grey-400,
  'grey-500': $grey-500,
  'grey-600': $grey-600,
  'cyan': $cyan,
  'yellow-light': $yellow-300
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $grey;
$success: $success;
$warning: $warning;
$danger: $danger;
$primary-light: $blue-300;
$light: $gray-100;
$dark: $gray-900;
$info: $cyan;
// scss-docs-end theme-color-variables

// theme-color variable start
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'warning': $warning,
  'danger': $danger
);
// theme-color variable end

// font-family variables start
$font-family-poppins-regular: 'poppinsregular';
$font-family-poppins-semibold: 'poppinssemibold';
$font-family-poppins-medium: 'poppinsmedium';
$font-family-nexa-bold: 'NexaW01-XBold';
// font-family variables end

//Gradient start
$bs-gradient: transparent linear-gradient(180deg, #112436 0%, #235684 100%) 0% 0% no-repeat
  padding-box;
$dark-gradient: $bs-gradient;
$bs-gradient-light: linear-gradient(180deg, #acd4f0 0%, #eaffff 100%);
$light-gradient: $bs-gradient-light;
$card-box-gradient: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.8) 0%,
  rgba(255, 255, 255, 0.2) 100%
);

$header-gradient: linear-gradient(
  90deg,
  rgba(255, 255, 255, 0.8) 0%,
  rgba(255, 255, 255, 0.4) 100%
);
$dark-gradient-welcome: transparent linear-gradient(301deg, #112436 0%, #235684 100%) 0% 0%
  no-repeat padding-box;
//Gradient end

//Card gradient
$card-gradient: transparent linear-gradient(180deg, #ffffffcc 0%, #ffffff33 100%) 0% 0% no-repeat
  padding-box;
$card-gradient-inner: linear-gradient(
  180deg,
  rgba(126, 187, 230, 0.8) 0%,
  rgba(126, 187, 230, 0.2) 100%
);
$card-box-shadow: 0px 4px 20px rgba(136, 194, 235, 0.25);
$card-top-height: 100px;
$card-border: 0.5px solid rgba(255, 255, 255, 0.5);
$card-radius: 1.25rem;

// border radius start
$card-border-radius: 1.25rem;
$card-image-radius: 1.1rem;
// border radius end

// modal popup start
$modal-width: 412px;
$modal-top-margin: 170px;
// modal popup width

// radius start
$dropdown-radius: 6px;
// radius end

// Gradients variable start
$topBarDarkBlue: #235684;
$bottomBarDarkBlue: #112436;
$topBarLightBlue: #acd4f0;
$bottomBarLightBlue: #eaffff;
// Gradients variable end

// font-weight start
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 600;
$font-weight-medium: 500;
// font-weight end

// font-size
$font-size-root: null;
$font-size-base: 16px; // Assumes the browser default, typically `16px`
$h1-font-size: 28px;
$h2-font-size: 20px;
$h3-font-size: 16px;
$h4-font-size: 15px;

$font-size-lg: 24px;
$font-size-md: 14px;
$font-size-sm: 13px;
$font-size-xs: 12px;
$font-10: 10px;
$font-9: 9px;
$font-11: 11px;
$font-13: 13px;
$font-18: 18px;
$font-19: 19px;
$font-22: 22px;
$font-23: 23px;
$font-26: 26px;
$font-30: 30px;

// line-heights start
$line-height-base: 1.5;
$line-height-lg: 34px;
$line-height-md: 24px;
$line-height-sm: 22px;
$line-height-xs: 20px;
$line-height-l: 15px;
$line-height-l6: 16px;
$line-height-l8: 18px;
$line-height-19: 19px;
$line-height-14: 14px;
$line-height-13: 13px;
$line-height-30: 30px;
$line-height-36: 36px;
$line-height-11: 11px;
// line-heights end

// letter-spacing start
$character-spacing-1-9: 1.92px;
$character-spacing-1-5: 1.5px;
$character-spacing-1-6: 1.6px;
$character-spacing-1-12: 1.12px;
$character-spacing-0-26: 0.26px;
$character-spacing-0-28: 0.28px;
$character-spacing-0-2: 0.2px;
$character-spacing-0-8: 0.8px;
$character-spacing-0-24: 0.24px;
$character-spacing-0-3: 0.32px;
$character-spacing-0-4: 0.39px;
$character-spacing-0-44: 0.44px;
$character-spacing-0-42: 0.42px;
$character-spacing-1-2: 1.2px;
$character-spacing-1-4: 1.4px;
$character-spacing-0-96: 0.96px;
// letter spacing end

// buttons border-radius-start
$btn-border-radius: 10px;
$input-btn-padding-y: 0.9375rem;
$input-btn-padding-x: 1.875rem;
// buttons border-radius-end

// buttons box-shadow start
$box-shadow: 0px 8px 20px #7ebbe6;
$box-shadow-sm: inset 0px 0px 5px rgba(0, 0, 0, 0.3019607843);
$header-top-box-shadow: 0px 6px 20px rgba(136, 194, 235, 0.5);
$btn-box-shadow: 0px 6px 16px #7090b066;
$secondary-btn-box-shadow: 0px 4px 10px #4a4a4a33;
$danger-btn-box-shadow: 0px 3px 6px #00000029;
$custom-dropdown-shadow: 0px 4px 6px #6897b133;
$progressbar-shadow: 0px 8px 24px #70ace8dd;

// buttons box-shadow end

// buttons height
$btn-height: 42px;

// Alignment
$text-l: left;
$text-r: right;
$text-c: center;

// Text-Transform
$text-uppercase: uppercase;
$text-lowercase: lowercase;

//text-decoration
$text-underline: underline;

//container
$container-max-widths: 764px;

// top-header background
$primary-light-gradient: transparent linear-gradient(180deg, #acd4f0 0%, #eaffff 100%) 0% 0%
  no-repeat padding-box;
$top-header-height: 127px;

//stepper
$stepper-w: 2.25rem;
$stepper-h: 2.25rem;
$stepper-mr: 2.16rem;
$stepper-radius: 0.813rem;

//tooltip
$tooltip-width: 135px;
$tooltip-icon-width: 1.25rem;
$tooltip-icon-height: 1.25rem;
$tooltip-font-size: $font-10;
$tooltip-line-height: $line-height-13;
$tooltip-letter-spacing: $character-spacing-0-3;
$tooltip-color: $white;
$tooltip-min-width: 82px;
$tooltip-min-height: 30px;
$tooltip-padding-y: 9px;
$tooltip-padding-x: 7px;

//form check
$form-check-input-bg: $input-bg;
$form-check-input-bg-check: #5dd166;
$form-check-input-width: 2.5rem;
$form-check-input-height: 1.5rem;
$form-check-input-border-radius: 1.375rem;
$form-check-input-focus-box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.243);
$form-check-input-focus-box-shadow-checked: inset 0px 3px 4px #00000053;
$form-check-padding-start: 0.625rem;

// form check radio
$form-check-radio-width: 1.375rem;
$form-check-radio-height: 1.375rem;

// button
$btn-border-color: $primary;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;

// dropdowns variable
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0.375rem;
$dropdown-padding-y: 0.88rem;
$dropdown-arrow-width: 0.625rem;
$dropdown-arrow-height: 0.625rem;

// input variables
$input-padding-left: 40%;
// $input-font-family: $font-family-poppins-regular;
$input-font-size: $font-size-md;
$input-font-weight: $font-weight-base;
$input-line-height: $line-height-36;

// Nav variable

$nav-link-padding-y: 0.313rem;
$nav-link-padding-x: 1.25rem;
$nav-link-font-size: $font-size-xs;
$nav-link-font-weight: $font-weight-normal;
$nav-link-color: $blue;
// $nav-font-family: $font-family-poppins-regular;
$nav-line-height: $line-height-xs;
$nav-letter-spacing: $character-spacing-0-2;
$nav-text-align: $text-c;

// $nav-tabs-link-active-font-family: $font-family-poppins-semibold;
$nav-tabs-link-active-font-size: $font-size-sm;
$nav-link-active-margin-y: -1px;
$nav-tabs-link-active-border-color: $primary;

//email modal-popup

$email-success-icon-width: 66px;
$email-success-icon-height: 66px;

//email otp input
$email-otp-input-width: 48px;

//popup gradient
$primary-light-gradient-modal: transparent
  linear-gradient(180deg, rgba(126, 187, 230, 0.875) 0%, #eaffff 100%) 0% 0% no-repeat padding-box;
