@import '@styles/variables';

.accordion-button::after {
  // transform: none !important;
  // transition: none !important;
  display: none !important;
}
.accordion-body {
  p {
    margin: 0;
  }
}

.accordion-button {
  .faq-question {
    color: $darkgreen;
  }
}