.nc-design-system {
  ul {
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

.color-box {
  width: 170px;
  height: 150px;
  padding: 20px 5px;
  border-radius: 5px;
}

#box-body-1 {
  background-color: $body-bg;
}
#box-body-2 {
  background-color: $body-color;
  color: white;
}
#box-text-1 {
  background-color: $darkgreen;
  color: white;
}
#box-text-2 {
  background-color: $darkgreen-75;
  color: white;
}
#box-text-3 {
  background-color: $darkgreen-50;
  color: white;
}
#box-gray-1 {
  background-color: $gray-10;
}
#box-gray-2 {
  background-color: $gray-20;
}
#box-gray-3 {
  background-color: $gray-40;
}
#box-gray-4 {
  background-color: $gray-60;
  color: white;
}
#box-gray-5 {
  background-color: $gray-80;
  color: white;
}
#box-gray-6 {
  background-color: $gray-100;
  color: white;
}
#box-mint-1 {
  background-color: $mint-20;
}
#box-mint-2 {
  background-color: $mint-40;
}
#box-mint-3 {
  background-color: $mint-60;
}
#box-mint-4 {
  background-color: $mint-80;
  color: white;
}
#box-mint-5 {
  background-color: $mint-100;
  color: white;
}
#box-yellow-1 {
  background-color: $yellow-20;
}
#box-yellow-2 {
  background-color: $yellow-40;
}
#box-yellow-3 {
  background-color: $yellow-60;
}
#box-yellow-4 {
  background-color: $yellow-80;
}
#box-yellow-5 {
  background-color: $yellow-100;
}
#box-tariff-1 {
  background-color: $smartS;
  color: white;
}
#box-tariff-2 {
  background-color: $smartS-80;
}
#box-tariff-3 {
  background-color: $smartM;
  color: white;
}
#box-tariff-4 {
  background-color: $smartM-80;
}
#box-tariff-5 {
  background-color: $smartL;
  color: white;
}
#box-tariff-6 {
  background-color: $smartL-80;
  color: white;
}
#box-tariff-7 {
  background-color: $smart6;
  color: white;
}
#box-tariff-8 {
  background-color: $smart6-80;
  color: white;
}
#box-tariff-9 {
  background-color: $smartS-60;
  color: white;
}
#box-tariff-10 {
  background-color: $smartM-60;
  color: white;
}
#box-tariff-11 {
  background-color: $smartL-60;
  color: white;
}
#box-tariff-12 {
  background-color: $smart6-60;
  color: white;
}
#box-tariff-13 {
  background-color: $orange;
  color: white;
}

#box-state-1 {
  background-color: $danger;
  color: white;
}
#box-state-2 {
  background-color: $success;
  color: white;
}
#box-state-3 {
  background-color: $info;
  color: white;
}
#box-state-4 {
  background-color: $warning;
}
#box-primary {
  background-color: $primary;
  color: white;
}
#box-secondary {
  background-color: $secondary;
}

.opacity-box {
  width: 200px;
  height: 150px;
  padding: 20px 5px;
  border-radius: 5px;
}
#box-opacity-1 {
  background-color: $white-80;
}
#box-opacity-2 {
  background-color: $white-60;
}
#box-opacity-3 {
  background-color: $white-40;
  color: white;
}
#box-opacity-4 {
  background-color: $white-20;
  color: white;
}

.shadow-box {
  width: 170px;
  height: 150px;
  padding: 20px 5px;
  margin-right: 50px !important;
}

.text-mint {
  color: $mint-100;
}
