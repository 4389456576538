@import '@theme/variables.scss';
@import '@styles/variables';

.stepper-item {
  li {
    display: inline-flex;
    border-radius: 100%;
    width: $stepper-w;
    height: $stepper-h;
    align-items: center;
    justify-content: center;
  }
  .stepper-bg {
    background: $mint-60;
    color: $white;
    border: $white 1px solid;

    &.active {
      background: $darkgreen;
      color: $white;
      border: none;
    }

    &.completed {
      background: $yellow-100;
      border: none;
    }
  }
}
