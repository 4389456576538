@import '@styles/variables';
@import '@styles/mixins';
@import '@theme/variables.scss';

.counter {
  @include common-counter;
  border-radius: $input-border-radius;
  &.plus-counter {
    width: 210px;
    &.day-counter {
      width: 240px;
    }
  }
  .counter-label {
    padding-top: 2px;
    color: $darkgreen;
    font-size: $font-26;
  }

  &.counter-light {
    background: $white;
    border: 1px solid transparent;
  }

  &.counter-dark {
    background: rgba($white, 0.2);
    border: 1px solid rgba($white, 0.8);
    .counter-label {
      color: $darkgreen-50;
    }
  }
}
