@import '@styles/variables';
@import '@styles/mixins';
@import '@theme/variables.scss';

.modal.popup-image {
  .modal-content {
    padding-bottom: 0 !important;
    .modal-main-content {
      .modal-header {
        .modal-title {
          text-align: $text-c;
          height: 3.25rem;
        }
      }
      .modal-image {
        height: 14.3125rem;
        width: 20.6rem;
      }
    }
  }
}
