@import '@styles/variables';
@import '@theme/variables.scss';

.video-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  .play-icon {
    position: absolute;
  }

  .preview-image {
    width: 96px;
    height: 96px;
    object-fit: cover;
  }
}

.player-close-wrap {
  padding-top: 1.25rem;
  padding: 1.25rem;
}
.player-close-icon {
  background-color: transparent !important;
}

// for landscape mode
@media screen and (max-width: 992px) and (orientation: landscape) {
  .video-landscape {
    height: 50vh !important;
  }
  .player-close-wrap {
    padding-top: 0 !important;
  }
  .player-close-icon {
// CHECK icon visibility in firefox, iphone
    position: relative; 
    z-index: 100 !important;
  }
}

// .player-close-icon {
//   top: -48px;
//   right: 28px;
// }

// .video-player {
//   width: auto;
//   height: auto !important;
//   display: flex;
//   align-items: center;
// }

// .video-player-container {

// }

// .modal {
//   &.video-player {
//     top: 0;
//     background: $black;
//     .modal-content {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       height: 100%;
//       background: $black;
//     }
//   }
// }

// .close-btn {
//   top: -48px;
//   right: 28px;
// }
