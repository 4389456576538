@import '@styles/variables';
@import '@theme/variables.scss';

.badge {
  padding: 6px 12px !important;
  .title {
    font-size: 13px;
    letter-spacing: $character-spacing-0-26;
  }
}
