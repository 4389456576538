// Variables
// Defaults: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
//
// Variable names match Figma ("design system: tokens") variable names

// -----------------------------------
// Colors
// -----------------------------------
$white: #fff;
$black: #000;

// With opacity
$white-80: rgba(255, 255, 255, 0.8);
$white-60: rgba(255, 255, 255, 0.6);
$white-40: rgba(255, 255, 255, 0.4);
$white-20: rgba(255, 255, 255, 0.2);

$gray-10: #eaebec; // Color/Gray/Gray-10
$gray-20: #d5d6d8; // Color/Gray/Gray-20
$gray-40: #abadb0; // Color/Gray/Gray-40
$gray-60: #818589; // Color/Gray/Gray-60
$gray-80: #575c61; // Color/Gray/Gray-80
$gray-100: #3c4559; // Color/Gray/Gray-100

// Override Bootstrap default gray values with custom values
// $gray-100: $gray-10; // Color/Gray/Gray-10
$gray-200: $gray-20; // Color/Gray/Gray-20
$gray-300: $gray-40; // Color/Gray/Gray-40
$gray-400: $gray-40; // Color/Gray/Gray-40
$gray-500: $gray-60; // Color/Gray/Gray-60
$gray-600: $gray-60; // Color/Gray/Gray-60
$gray-700: $gray-60; // Color/Gray/Gray-60
$gray-800: $gray-80; // Color/Gray/Gray-80
$gray-900: $gray-100; // Color/Gray/Gray-100

$mint-20: #d6f0ef;
$mint-40: #ade2de;
$mint-60: #84d3ce;
$mint-80: #5cc4be;
$mint-100: #33b6ae;

// Text colors
$darkgreen-50: #83a6a8;
$darkgreen-75: #467a7d; // for button hover
$darkgreen: #074d52;

$yellow-20: #fff5d6;
$yellow-40: #ffebad;
$yellow-60: #ffe085;
$yellow-80: #ffd65c;
$yellow-100: #ffcc33;
$accent: $yellow-100;

// Tariffs
$smartS: #e0424e;
$smartS-80: #e8717a;
$smartM: #f49a54;
$smartM-80: #f7b37f;
$smartL: #77255f;
$smartL-80: #995b87;
$smart6: #215374;
$smart6-80: #50809a;

// DayFlat / Speedon
$dayFlat: #f90;
$speedon: #FF9900;

// 5g tariffs
$smartS-60: #ec8e95;
$smartM-60: #f8c298;
$smartL-60: #ad7c9f;
$smart6-60: #7a98ac;

// Generic
$blue: #00a1c5; // Color/states/info
$red: #d23030; // Color/states/error
$yellow: $yellow-100; // Color/states/warning
$green: #00991e; // Color/states/success
$orange: #ff9900; // same as "dayflat" and "speedon"
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $orange:  #fd7e14;
// $teal:    #20c997;
// $cyan:    #0dcaf0;

// Override Bootstrap default theme colors with custom values
$primary: $darkgreen; // CHECK
$secondary: $mint-100; // CHECK
$light: $gray-10;
$dark: $gray-100;
$success: $green; // Color/states/success
$info: $blue; // Color/states/info
$warning: $yellow; // Color/states/warning
$danger: $red; // Color/states/error

$body-bg: $mint-40;
$body-color: $darkgreen;
// $headings-color: $darkgreen;

// -----------------------------------
// Custom maps
// -----------------------------------
$norma-colors: (
  'white': $white,
  'white-80': $white-80,
  'white-60': $white-60,
  'white-40': $white-40,
  'white-20': $white-20,
  'black': $black,
  'gray-10': $gray-10,
  'gray-20': $gray-20,
  'gray-40': $gray-40,
  'gray-60': $gray-60,
  'gray-80': $gray-80,
  'gray-100': $gray-100,
  'mint-20': $mint-20,
  'mint-40': $mint-40,
  'mint-60': $mint-60,
  'mint-80': $mint-80,
  'mint-100': $mint-100,
  'darkgreen': $darkgreen,
  'darkgreen-75': $darkgreen-75,
  'darkgreen-50': $darkgreen-50,
  'yellow-20': $yellow-20,
  'yellow-40': $yellow-40,
  'yellow-60': $yellow-60,
  'yellow-80': $yellow-80,
  'yellow-100': $yellow-100,
  'smartS': $smartS,
  'smartS-60': $smartS-60,
  'smartS-80': $smartS-80,
  'smartM': $smartM,
  'smartM-60': $smartM-60,
  'smartM-80': $smartM-80,
  'smartL': $smartL,
  'smartL-60': $smartL-60,
  'smartL-80': $smartL-80,
  'smart6': $smart6,
  'smart6-60': $smart6-60,
  'smart6-80': $smart6-80,
  'dayflat': $dayFlat,
  "speedon": $speedon,
  'orange': $orange,
  'blue': $blue,
  'red': $red,
  'yellow': $yellow-100,
  'accent': $yellow-100
);

// -----------------------------------
// Typography
// -----------------------------------

// Font family
$font-family-base: 'Real Text';
$font-family-realtext: 'Real Text';
$font-family-realhead: 'Real Head';
$font-family-doomsday: 'Universal Doomsday';
// $font-family-doomsday-medium: 'Universal Doomsday Medium';
$font-family-doomsday-medium: 'Universal Doomsday';

$headings-font-family: $font-family-doomsday;

// Font sizes

// $font-size-base affects the font size of the body text
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

// Real Text font sizes
$realtext-copy-font-size: $font-size-base; // 16px
// $realtext-input-text-size: 0.938rem; // 15px
$realtext-input-text-size: 16px; // NOTE: smaller then 16px causes zoom-in (iphone, all browsers)
$realtext-input-placeholder-text-size: 15px;
$realtext-button-font-size: 0.938rem; // 15px
$realtext-footnote-font-size: 0.813rem; // 13px

// Bootstrap default font sizes
// $h1-font-size: $font-size-base * 2.5; // 40px
// $h2-font-size: $font-size-base * 2; // 32px
// $h3-font-size: $font-size-base * 1.75; // 28px
// $h4-font-size: $font-size-base * 1.5; // 24px
// $h5-font-size: $font-size-base * 1.25; // 20px
// $h6-font-size: $font-size-base; // 16px

// Real Head font sizes
$realhead-h1-font-size: 2.75rem; // 44px
$realhead-h2-font-size: 2rem; // 32px
$realhead-h3-font-size: 1.5rem; // 24px
$realhead-h4-font-size: 1.25rem; // 20px
$realhead-h5-font-size: 1.25rem;
$realhead-h6-font-size: 1rem;
$realhead-subhead: 0.813rem; // 13px

// Universal Doomsday font sizes
$doomsday-h1-font-size: 2.75rem; // 44px
$doomsday-h2-font-size: 2.125rem; // 34px
$doomsday-h3-font-size: 1.625rem; // 24px
$doomsday-h4-font-size: 1.25rem; // 20px
$doomsday-h5-font-size: 1.25rem;
$doomsday-h6-font-size: 1rem;

$doomsday-button-font-size: 0.938rem; // 15px
$doomsday-copy-font-size: 1rem; // 16px
$doomsday-footnote-font-size: 0.875rem; // 14px

// -----------------------------------
// Other variables
// -----------------------------------

// Spacers
// note: only 2px, 6px, 10px namings are exceptions to the rule because they were added later
$nc-spacer: 1rem;
$nc-spacers: (
  0: 0,
  2px: $nc-spacer * 0.125,
  // 2px
  1: $nc-spacer * 0.25,
  // 4px
  6px: $nc-spacer * 0.375,
  // 6px
  2: $nc-spacer * 0.5,
  // 8px
  10px: $nc-spacer * 0.625,
  // 10px
  3: $nc-spacer * 0.75,
  // 12px
  4: $nc-spacer,
  // 16px
  5: $nc-spacer * 1.25,
  // 20px
  6: $nc-spacer * 1.5,
  // 24px // md
  7: $nc-spacer * 1.75,
  // 28px
  8: $nc-spacer * 2,
  // 32px // lg
  9: $nc-spacer * 2.25,
  // 36px
  10: $nc-spacer * 2.5,
  // 40px
  11: $nc-spacer * 2.75,
  // 44px
  12: $nc-spacer * 3,
  // 48px // xl
  13: $nc-spacer * 3.25,
  // 52px
  14: $nc-spacer * 3.5,
  // 56px
  15: $nc-spacer * 3.75,
  // 60px
  16: $nc-spacer * 4,
  // 64px
  17: $nc-spacer * 4.25,
  // 68px
  18: $nc-spacer * 4.5,
  // 72px
  19: $nc-spacer * 4.75,
  // 76px
  20: $nc-spacer * 5,
  // 80px
);

// Shadows
$box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25); // "neutral" in design tokens
$box-shadow-sm: 0px 15px 30px 0px rgba(0, 0, 0, 0.15); // "small" in design tokens
$box-shadow-xs: 0px 7px 15px 0px rgba(0, 0, 0, 0.07); // "small" in design tokens
// $box-shadow-lg: 0 1rem 3rem rgba($black, 0.175); // default
// $box-shadow-inset: inset 0 1px 2px rgba($black, 0.075); // default
$box-shadow-modal: 0px -2px 4px 0px rgba(0, 0, 0, 0.25); // "modal" in design tokens
$enable-negative-margins: 'true';

//  Tooltip
$tooltip-color: $white;
$tooltip-bg: $gray-100;
$tooltip-font-family: $font-family-realtext;
$tooltip-font-size: 16px;
$tooltip-font-weight: 600;
$tooltip-max-width: 200px;
$tooltip-padding-y: 15px;
$tooltip-padding-x: 15px;

// Input
$input-bg: rgba($white, 0.2);
$input-box-shadow: $input-bg;
$input-color: $darkgreen;
$input-placeholder-color: $darkgreen-50;
$input-plaintext-color: $darkgreen-50;

$input-group-addon-bg: rgba($white, 0.2);
$input-group-addon-border: none;

$input-border-radius: 60px;
$input-border-radius-lg: 60px;
$input-border-color: rgba($white, 0.6);
$input-border-width: 0;
$input-border-radius-code: 20px;

$input-height: 20px;
$input-padding-y: 16px;
$input-padding-x: 20px;

$input-font-family: $font-family-realtext;
// $input-font-size: 15px;
$input-font-size: 16px; // NOTE: smaller then 16px causes zoom-in (iphone, all browsers)
$input-font-weight: 400;

$input-font-family-voucher: $font-family-doomsday;
$input-font-size-voucher: 26px !default;
$input-font-size-lg: $input-font-size-voucher;
$input-font-weight-voucher: 700;

// $input-line-height: normal;

$input-focus-border-color: none;
$input-focus-border-color: $mint-100;

$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

$form-label-margin-bottom: 2px;
$form-label-color: $darkgreen;
$form-label-font-family: $font-family-realhead;
$form-label-font-size: 13px;
$form-label-font-size-lg: 16px;
$form-label-font-weight: 400;
$form-label-line-height: 1.82px;
$form-label-letter-spacing: 1.82px;
// Border radius
$border-radius: 16px; // rounded
$border-radius-sm: 10px; // rounded-1
// rounded-2 = $border-radius
$border-radius-lg: 20px; // rounded-3
$border-radius-xl: 30px; // rounded-4
$border-radius-xxl: 60px; // rounded-5

// Container
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1320px
// ) !default;

// Offcanvas
$offcanvas-padding-y: 0;

$accordion-border-width: 0px;

// TODO move modal/popup styles from src/theme/variables.scss here
// Modal