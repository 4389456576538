::-webkit-scrollbar-track {
  border: none;
  background-color: #eaebec;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
  background-color: #eaebec;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #abadb0;
}
