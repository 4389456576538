@import '@styles/variables';
@import '@theme/variables.scss';

.payment-field {
  background: rgba($white, 0.8);
  border-radius: 16px;
  padding: 12px 16px 12px 12px;
  .label {
    color: $gray-80;
  }
}
