@import '@styles/variables';

.input-group-code {
  width: 66px;
  border-radius: $input-border-radius-code;
  .form-control {
    border-radius: $input-border-radius-code !important;
    text-align: center;
    padding: 18px 15px !important;
    color: $mint-100;
    font-family: $input-font-family-voucher;
    font-size: 44px !important;
    font-weight: $input-font-weight-voucher;
    line-height: normal;
    &:focus {
      color: $mint-100;
    }
  }
  &.invalid {
    .form-control {
      color: $danger;
    }
    border: 2px solid rgba($white, 1);
  }

  // hides up/down arrows in number field
  input[type='number'] {
    appearance: none;
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  // margin between all child except first and last
  // &:first-child {
  //   margin-right: 15px;
  // }
  // &:last-child {
  //   margin-left: 15px;
  // }
  // &:not(:first-child):not(:last-child) {
  //   margin: 0 15px;
  // }

  margin-right: 24px;
  &:first-child {
    margin-right: 24px;
  }
  &:last-child {
    margin-right: 0px;
  }
}
