@import '@styles/variables';

.input-group-datepicker {
  .form-control-placeholder {
    width: calc(100% - 90px);
    padding: $input-padding-y $input-padding-x;
    color: $darkgreen;
    appearance: none; // Fix appearance for date inputs in Safari
    background-color: $input-bg;
    font-family: $input-font-family;
    font-size: $input-font-size;
    font-weight: $input-font-weight;
    // line-height: $input-line-height;
  }
}
.input-group-datepicker-list {
  height: 238px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow-y: scroll;
  .date-picker-row {
    // overflow: hidden;
    // overflow-y: auto;
    .date-picker-day {
      height: 152px;
      overflow: hidden;
      div {
        height: 100%;
        // overflow: hidden;
        overflow-y: auto;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .date-picker-month {
      height: 152px;
      div {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
    .date-picker-year {
      height: 152px;
      div {
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.popup-date-picker {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  /* background: red; */
  width: 100vw;
  height: 100%;
  background: rgba(7, 77, 82, 0.5);
  -webkit-backdrop-filter: blur(3px) !important;
  backdrop-filter: blur(3px) !important;
}
