@import '@styles/variables';
@import '@theme/variables';

.faq-tab {
  width: 171px;
  height: 96px;
  border-radius: 20px;
  &.active {
    background-color: $yellow-100;
  }
  background-color: rgba($white, 0.4);
}