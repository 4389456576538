@import '@styles/variables';
@import '@styles/mixins';

// Apply Mixin to different sizes & colors
// .btn-radio {
//   @include primary-radio-btn;
// }
.radio {
  // margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-icon {
      &:before {
        content: '';
        background: $white;
        border-radius: 100%;
        border: 2px solid #d5d6d8;
        display: inline-block;
        width: 24px;
        height: 24px;
        // position: relative;
        // margin-top: auto;
        top: -0.2em;
        // margin-right: 1em; 
        vertical-align: middle;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-icon {
        &:before {
          background-color: $darkgreen;
          box-shadow: inset 0 0 0 4px $white;
        }
      }
    }
    &:focus {
      + .radio-icon {
        &:before {
          outline: none;
          border-color: #d5d6d8;
        }
      }
    }
    &:disabled {
      + .radio-icon {
        &:before {
          box-shadow: inset 0 0 0 4px $white;
          border-color: darken($white, 25%);
          background: darken($white, 25%);
        }
      }
    }
  }
}