@import '@styles/global';

.navbar-custom {
  background-color: rgba(173, 226, 222, 0.4) !important;
}

.navbar-nav {
  padding-right: 0 !important;

  .nav-item:last-child {
    padding-right: 0 !important;
  }

  .nav-link {
    font-family: $font-family-doomsday;
    font-weight: $font-weight-medium;
    font-size: 1rem !important;
    color: $darkgreen-50;
    &:hover {
      color: $mint-100;
    }
  }

  .nav-link.active {
    color: $darkgreen !important;
  }

  .nav-link.inactive {
    color: $darkgreen-50;
    &:hover {
      color: $mint-100;
    }
  }

  .nav-link-mobile {
    font-family: $font-family-doomsday;
    font-weight: $font-weight-bold;
    font-size: 2.125rem !important; // 34px
    color: $white;
  }
}

.offcanvas-body-mobile {
  background-color: $mint-80 !important;
}

@media (max-width: 1199px) {
  .navbar-custom {
    .nav-link {
      &:hover {
        color: $darkgreen !important; // change hover color only for nav items in burger menu
      }
    }
  }
}
