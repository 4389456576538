@import '@styles/variables';
@import '@styles/mixins';
@import '@theme/variables.scss';

.modal {
  bottom: 0;
  z-index: 1;
  background: rgba(7, 77, 82, 0.5);
  backdrop-filter: blur(3px) !important;
  text-align: $text-c;
  .modal-content {
    max-width: 100%;
    height: auto;
    overflow-y: auto;
    background: $white;
    .modal-line {
      width: 88px;
      border: 2px solid $gray-20;
      border-radius: 2px;
    }
    @media only screen and (min-width: 0px) and (max-width: 575.98px) {
      height: auto;
      max-height: 85%;
      overflow-y: auto;
    }
  }
  .modal-header {
    text-align: $text-l;
    padding: 0;
    .modal-title {
      font-size: $font-26;
      font-weight: $font-weight-bold;
      line-height: normal;
      font-style: normal;
      font-family: $headings-font-family;
      .mint-text {
        color: $mint-100;
      }
    }
  }
  .modal-body {
    padding: 0;
    text-align: $text-l;
    font-size: $font-size-base;
    font-family: $font-family-realtext;
    font-weight: $font-weight-normal;
    line-height: normal;
    font-style: normal;
    color: $darkgreen;
    p {
      margin: 0;
    }
  }
  .close {
    top: 1rem;
    right: 1rem;
    background: $white;
  }
}
